.card {
    $this: &;

    display: flex;
    background-color: map-get($default-colours, primary);
    color: map-get($default-colours, grey-dark);
    border-radius: $border-radius;
    //overflow: hidden;

    &--dark {
        background-color: map-get($default-colours, grey-light);
    }

    @each $theme,
    $color in $theme-colours {
        &--#{$theme} {
            background-color: #{$color};
        }
    }

    &__media {
        flex-basis: 120px;

        @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
            flex-basis: 180px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: map-get($default-spacing, 'tablet');

        &--small {
            padding: map-get($default-spacing, 'desktop');
        }

        @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
            padding: map-get($default-spacing, 'desktop');
        }
    }

    &__header {
        flex: 1;
        margin-bottom: 0.5rem;
    }

    &__title {
        font-size: 1.2em;
        line-height: 1.3em;
        font-weight: 100;
        margin: 0;

        &--dark {
            color: map-get($default-colours, primary);
        }

        &--small {
            font-size: 20px;
        }
    }

    &__subtitle {
        font-size: 0.875em;

        &--dark {
            color: map-get($default-colours, white);
        }
    }

    &__body {
        flex: 1;
        flex-basis: auto;
    }

    &__actions {
        flex-direction: row;
        margin-top: 1rem;
        
        > * {
            &:not(:last-child) {
                margin-right: map-get($default-spacing, 'desktop');
            }
        }
    }
}