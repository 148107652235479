/* autoprefixer grid: autoplace */
/**
 * Based on
 *
 *  - reset.css 2.0 by Eric Meyer
      (public domain)
 *    http://meyerweb.com/eric/tools/css/reset/
 *
 *  - normalize.css 8.0.1 by Nicolas Gallagher and Jonathan Neal
 *    (licensed under MIT)
 *    https://github.com/necolas/normalize.css
 *
 *  - Based on Reboot from Bootstrap 4.2.1
 *    (licensed under MIT)
 *    https://github.com/twbs/bootstrap
 */
/**
 * IE10+ doesn't honor `<meta name="viewport">` in some cases
 */
@-ms-viewport {
  width: device-width; }

/**
 * general reset
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, main {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/**
 * HTML5 display-role reset for older browsers
 */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section,
main, summary {
  display: block; }

/**
 * inherit box model for all elements
 */
*,
*::before,
*::after {
  box-sizing: inherit; }

/**
 * html root rules
 * 1. set border-box for inheritance
 * 2. avoid 300ms click delay on touch devices that support the `touch-action`
 *    CSS property
 * 3. Prevent adjustments of font size after orientation changes in IE, on
 *    Windows Phone and iOS.
 * 4. Setting @viewport causes scrollbars to overlap content in IE11 and Edge,
 *    so we force a non-overlapping, non-auto-hiding scrollbar to counteract.
 * 5. Change the default tap highlight to be completely transparent in iOS.
 */
html {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  touch-action: manipulation;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  /* 4 */
  -ms-overflow-style: scrollbar;
  /* 5 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/**
 * body rules
 * 1. reset line-height to 1
 * 2. set base font-family to sans-serif
 * 3. Set an explicit initial text-align value so that we can later use the
 *    `inherit` value on things like `<th>` elements.
 */
body {
  /* 1 */
  line-height: 1;
  /* 2 */
  font-family: sans-serif;
  /* 3 */
  text-align: left; }

/**
 * Lists
 */
ol, ul {
  list-style: none; }

/**
 * Quotes
 */
blockquote, q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

/**
 * Tables
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

caption {
  caption-side: bottom; }

/**
 * Table Headers
 * 1. Matches default `<td>` alignment by inheriting from the `<body>`, or the
 *    closest parent with a set `text-align`.
 */
th {
  /* 1 */
  text-align: inherit; }

/**
 * Horizontal Lines
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  /* 1 */
  box-sizing: content-box;
  height: 0;
  /* 2 */
  overflow: visible; }

/**
 * Preformatted Text
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Don't allow content to break outside
 * 3. We have @viewport set which causes scrollbars to overlap content in IE11
 *    and Edge, so we force a non-overlapping, non-auto-hiding scrollbar to
 *    counteract.
 */
pre,
code,
kbd,
samp {
  /* 1 */
  font-family: monospace, monospace; }

pre {
  /* 2 */
  overflow: auto;
  /* 3 */
  -ms-overflow-style: scrollbar; }

/**
 * Links
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  /* 1 */
  background-color: transparent;
  /* 2 */
  -webkit-text-decoration-skip: objects; }

/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 * 3. Add explicit cursor to indicate changed behavior.
 * 4. Prevent the text-decoration to be skipped.
 */
abbr[title] {
  /* 1 */
  border-bottom: none;
  /* 2 */
  text-decoration: underline;
  text-decoration: underline dotted;
  /* 3 */
  cursor: help;
  /* 4 */
  text-decoration-skip-ink: none; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none; }

/**
 * Hide SVG overflow in IE
 */
svg:not(:root) {
  overflow: hidden; }

/**
 * Remove the default `border-radius` that macOS Chrome adds.
 * Details at https://github.com/twbs/bootstrap/issues/24093
 */
button {
  border-radius: 0; }

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 * Credit: https://github.com/suitcss/base/
 */
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

/**
 * form element resets
 * 1. Remove the margin in Firefox and Safari
 * 2. inherit font rules
 */
input,
button,
select,
optgroup,
textarea {
  /* 1 */
  margin: 0;
  /* 2 */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="reset"],
[type="submit"],
[type="button"] {
  /* 2 */
  -webkit-appearance: button; }

/**
 * Remove the default appearance of temporal inputs to avoid a Mobile Safari
 * bug where setting a custom line-height prevents text from being vertically
 * centered within the input.
 * See https://bugs.webkit.org/show_bug.cgi?id=139848
 * and https://github.com/twbs/bootstrap/issues/11266
 */
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Textareas should really only resize vertically so they don't break their
 *    (horizontal) containers.
 */
textarea {
  overflow: auto;
  resize: vertical; }

/**
 * Show the overflow in IE.
 */
button,
input {
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 */
button,
select {
  text-transform: none; }

/**
 * Remove the inheritance of word-wrap in Safari.
 * See https://github.com/twbs/bootstrap/issues/24990
 */
select {
  word-wrap: normal; }

/**
 * Remove inner border and padding from Firefox, but don't restore the outline
 * like Normalize.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * 1. Add the correct box sizing in IE 10-
 * 2. Remove the padding in IE 10-
 */
input[type="radio"],
input[type="checkbox"] {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  padding: 0; }

/**
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 * Credit: https://github.com/suitcss/base
 */
[tabindex="-1"]:focus {
  outline: 0 !important; }

/**
 * Browsers set a default `min-width: min-content` on fieldsets,
 * unlike e.g. `<div>`s, which have `min-width: 0` by default.
 * So we reset that to ensure fieldsets behave more like a standard block element.
 * See https://github.com/twbs/bootstrap/issues/12359
 * and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
 */
fieldset {
  min-width: 0; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Set display to block for all browsers
 */
legend {
  /* 1 */
  max-width: 100%;
  white-space: normal;
  /* 2 */
  color: inherit;
  /* 3 */
  display: block; }

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline; }

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto; }

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type="checkbox"],
[type="radio"] {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  padding: 0; }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  /* 1 */
  -webkit-appearance: textfield;
  /* 2 */
  outline-offset: -2px; }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  /* 1 */
  -webkit-appearance: button;
  /* 2 */
  font: inherit; }

/**
 * Correct element display for output
 */
output {
  display: inline-block; }

/**
 * Add the correct display in IE 10+.
 */
template {
  display: none; }

[hidden] {
  display: none; }

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: url("../assets/fonts/HelveticaNeueLTStd-Th.woff2") format("woff2"), url("../assets/fonts/HelveticaNeueLTStd-Th.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: url("../assets/fonts/HelveticaNeueLTStd-Bd.woff2") format("woff2"), url("../assets/fonts/HelveticaNeueLTStd-Bd.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: url("../assets/fonts/HelveticaNeueLTStd-Roman.woff2") format("woff2"), url("../assets/fonts/HelveticaNeueLTStd-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

html {
  font-size: 85%; }
  @media only screen and (min-width: 1400px) {
    html {
      font-size: 100%; } }

body {
  font-family: 'Helvetica Neue LT Std', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased; }

h1 {
  font-size: 3.25em;
  font-weight: 100; }

h2 {
  font-size: 1.75em;
  font-weight: 100; }

h3 {
  font-size: 1.375em;
  line-height: 26px;
  font-weight: 100; }

h4 {
  font-size: 1.25em;
  font-weight: 100;
  line-height: 28px; }

h5 {
  font-size: 1em;
  font-weight: bold;
  line-height: 24px; }

h6 {
  font-size: .938em;
  font-weight: bold;
  line-height: 20px; }

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5em; }

p, li {
  font-size: .875em;
  line-height: 1.5em; }
  p strong, li strong {
    font-weight: bold; }

a {
  text-decoration: none;
  color: inherit; }

button:focus {
  outline: none; }

.response-container {
  width: 100%;
  height: 100%;
  padding-top: 56%;
  position: relative; }
  .response-container * {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%; }

.global__header--fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 98; }

.dashboard {
  display: grid;
  margin: auto;
  max-width: 90rem;
  padding: 1rem;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  grid-template-areas: 'Zone1' 'Zone2' 'Zone3' 'Zone4'; }
  @media only screen and (min-width: 960px) {
    .dashboard {
      grid-template-areas: 'Zone1 Zone1 Zone1 Zone2' 'Zone3 Zone3 Zone3 Zone3' 'Zone4 Zone4 Zone4 Zone4';
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding: 1.25rem;
      grid-row-gap: 1.25rem;
      grid-column-gap: 1.25rem; } }
  .dashboard__zone {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr; }
    .dashboard__zone--1 {
      grid-area: Zone1; }
    .dashboard__zone--2 {
      grid-area: Zone4; }
      @media only screen and (min-width: 960px) {
        .dashboard__zone--2 {
          grid-area: Zone2; } }
    .dashboard__zone--3 {
      grid-area: Zone3; }
    .dashboard__zone--4 {
      grid-area: Zone2; }
      @media only screen and (min-width: 960px) {
        .dashboard__zone--4 {
          grid-area: Zone4; } }
    .dashboard__zone--3, .dashboard__zone--4 {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 1rem;
      grid-row-gap: 1rem; }
      @media only screen and (min-width: 960px) {
        .dashboard__zone--3, .dashboard__zone--4 {
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-column-gap: 1.25rem; } }

.page {
  display: flex;
  min-height: calc(100vh - 4rem); }
  .page__nav {
    background-color: #333333;
    display: none; }
    @media only screen and (min-width: 960px) {
      .page__nav {
        display: flex;
        flex-basis: 300px; } }
  .page__nav-mobile {
    background-color: #333333;
    width: 300px; }
  .page__main {
    flex: 1; }
  .page__main-inner {
    padding: 1em;
    margin: 0 auto;
    max-width: 60rem; }
    @media only screen and (min-width: 960px) {
      .page__main-inner {
        padding: 2em;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: 'breadcrumbs' 'header' 'header' 'article' 'footer'; } }
    .page__main-inner--withsidebar {
      display: grid;
      grid-template-areas: 'breadcrumbs' 'header' 'article' 'footer' 'sidebar';
      grid-column-gap: 1.25rem;
      grid-template-columns: 1fr;
      grid-row-gap: 1rem; }
      @media only screen and (min-width: 960px) {
        .page__main-inner--withsidebar {
          max-width: 80rem;
          grid-template-areas: 'breadcrumbs breadcrumbs' 'header header' 'article sidebar' 'footer sidebar';
          grid-template-columns: 1fr 300px; } }
  .page__breadcrumbs {
    grid-area: breadcrumbs; }
  .page__header {
    grid-area: header; }
  .page__article {
    grid-area: article; }
  .page__sidebar {
    grid-area: sidebar; }
    .page__sidebar > *:not(:last-child) {
      margin-bottom: 1.25rem; }
  .page__footer {
    grid-area: footer; }

.fullwidth {
  padding: 2em; }
  @media only screen and (min-width: 960px) {
    .fullwidth {
      padding: 4em; } }

.animated-slide {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  border-radius: 4px; }
  .animated-slide h2 {
    font-size: 2rem;
    font-weight: 300; }
  .animated-slide h3 {
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 600; }
  .animated-slide__slide {
    flex: 1;
    position: relative;
    padding: 30px 20px;
    width: 100%;
    height: 100%; }
  .animated-slide__title {
    color: #fff; }
    .slick-active .animated-slide__title {
      position: relative;
      animation: slideInLeft ease 2s; }
  .animated-slide__icon {
    position: absolute;
    right: 10px;
    bottom: 10px; }
    .slick-active .animated-slide__icon {
      animation: slideInRight ease 2s; }
  .animated-slide .slick-slider {
    height: 100%; }
  .animated-slide .slick-list {
    height: 100%; }
  .animated-slide .slick-track {
    height: 100%; }
  .animated-slide .slick-track .slick-slide {
    height: 100%; }
  .animated-slide .slick-track .slick-slide > div {
    height: 100%; }

@keyframes slideInLeft {
  0% {
    opacity: 0;
    left: -200px; }
  100% {
    opacity: 1;
    left: 0; } }

@keyframes slideInRight {
  0% {
    opacity: 0;
    right: -200px; }
  100% {
    opacity: 1;
    right: 10px; } }

.breadcrumbs {
  margin-bottom: 2em; }
  .breadcrumbs__item {
    display: inline-block; }
    .breadcrumbs__item:not(:last-child)::after {
      content: '//';
      margin: 0 1em;
      color: #9e9e9e; }
  .breadcrumbs__link {
    color: #9e9e9e; }

.button {
  display: inline-block;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 4px;
  text-transform: uppercase;
  background-position: center;
  transition: background 0.8s; }
  .button--medium {
    padding: 0.8rem 1rem;
    font-size: 1rem; }
    @media only screen and (min-width: 960px) {
      .button--medium {
        padding: 0.4rem 0.6rem;
        font-size: 0.75rem; } }
  .button--dark {
    background-color: #262626;
    color: #ffffff; }
    .button--dark.button--outline {
      color: #262626; }
  .button--light {
    background-color: #F7D1C4;
    color: #262626; }
    .button--light.button--outline {
      color: #F7D1C4; }
  .button--solid {
    border: 1px solid transparent; }
  .button--outline {
    background-color: transparent;
    border: 1px solid currentColor; }
  .button--disabled {
    opacity: 0.5; }
  .button--expand {
    width: 100%; }
  .button--icon {
    text-align: left;
    padding-right: 2rem; }
  .button__icon {
    position: absolute;
    right: 0.8rem;
    top: 50%;
    transform: translateY(-50%); }
  .button--round {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 0 2px;
    float: right; }

.button-group {
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 960px) {
    .button-group--column {
      flex-direction: row; } }
  .button-group__item {
    flex: 1;
    flex-basis: auto; }
    .button-group__item:not(:last-child) {
      margin-bottom: 10px; }
    @media only screen and (min-width: 960px) {
      .button-group--column .button-group__item:not(:last-child) {
        margin-right: 20px;
        margin-bottom: 0; } }
    .button-group__item > * {
      width: 100%; }

.card {
  display: flex;
  background-color: #F7D1C4;
  color: #262626;
  border-radius: 4px; }
  .card--dark {
    background-color: #6C6C6C; }
  .card--primary {
    background-color: #F7D1C4; }
  .card--theme-1 {
    background-color: #99D4DC; }
  .card--theme-2 {
    background-color: #F07171; }
  .card--theme-3 {
    background-color: #DD94BE; }
  .card--theme-4 {
    background-color: #C2E398; }
  .card__media {
    flex-basis: 120px; }
    @media only screen and (min-width: 1400px) {
      .card__media {
        flex-basis: 180px; } }
    .card__media img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .card__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 1rem; }
    .card__content--small {
      padding: 1.25rem; }
    @media only screen and (min-width: 1400px) {
      .card__content {
        padding: 1.25rem; } }
  .card__header {
    flex: 1;
    margin-bottom: 0.5rem; }
  .card__title {
    font-size: 1.2em;
    line-height: 1.3em;
    font-weight: 100;
    margin: 0; }
    .card__title--dark {
      color: #F7D1C4; }
    .card__title--small {
      font-size: 20px; }
  .card__subtitle {
    font-size: 0.875em; }
    .card__subtitle--dark {
      color: #ffffff; }
  .card__body {
    flex: 1;
    flex-basis: auto; }
  .card__actions {
    flex-direction: row;
    margin-top: 1rem; }
    .card__actions > *:not(:last-child) {
      margin-right: 1.25rem; }

.carousel {
  background-color: #383838;
  border-radius: 4px;
  display: flex;
  position: relative;
  overflow: hidden;
  padding: 20px 3rem; }
  .carousel__list {
    display: flex;
    flex: 1;
    position: relative;
    overflow: hidden; }
  .carousel__track {
    display: flex;
    flex: 1;
    transition: all 300ms; }
  .carousel__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem; }
  .carousel__nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .carousel__nav--prev {
      left: 1rem; }
    .carousel__nav--next {
      right: 1rem; }
  .carousel__button {
    padding: 1rem .5rem;
    margin: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    color: white;
    opacity: 0.2; }

.slide {
  width: 100%;
  background: pink;
  color: black;
  height: 50px; }

.checkbox {
  font-size: 0.938em; }
  .checkbox__hidden {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px; }
  .checkbox__icon {
    background: white;
    border-radius: 3px;
    display: inline-block;
    height: 16px;
    position: relative;
    top: -1px;
    transition: all 150ms;
    vertical-align: middle;
    width: 16px; }
    .checkbox__icon svg {
      visibility: hidden; }
    .checkbox__icon--checked svg {
      visibility: visible; }
  .checkbox__label {
    margin-left: 0.5rem; }

.content-modal {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }
  .content-modal::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; }
  .content-modal__overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: 0 !important;
    overflow: hidden auto;
    background-color: rgba(0, 0, 0, 0.8); }
  .content-modal__inner {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045; }
  .content-modal__content {
    position: relative;
    max-width: 800px;
    width: 100%;
    background-color: white; }
    .content-modal__content--large {
      max-width: 1400px; }
  .content-modal__close {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    padding: 20px;
    font-size: 28px;
    line-height: 16px;
    background-color: transparent; }

.ReactModal__Body--open {
  overflow-y: hidden; }

.content-modal-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px; }
  @media only screen and (min-width: 960px) {
    .content-modal-grid {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr); } }
  @media only screen and (min-width: 1400px) {
    .content-modal-grid {
      grid-column-gap: 30px;
      grid-row-gap: 30px; } }

.divider {
  height: 1.25rem; }

.expand-button {
  padding: 10px;
  background-color: #F7D1C4;
  border: 0;
  margin: 0;
  line-height: 0; }
  .expand-button > .MuiSvgIcon-root {
    width: 38px;
    height: 38px; }

.feature-button {
  background-size: cover;
  position: relative;
  padding-top: 76%; }
  .feature-button__main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); }
    .feature-button__main:hover {
      background-color: rgba(0, 0, 0, 0.6); }
  .feature-button__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 5px;
    border-width: 6px;
    border-style: solid; }
    @media only screen and (min-width: 1400px) {
      .feature-button__content {
        padding: 24px; } }
    .feature-button__content--primary {
      border-color: #F7D1C4; }
    .feature-button__content--theme-1 {
      border-color: #99D4DC; }
    .feature-button__content--theme-2 {
      border-color: #F07171; }
    .feature-button__content--theme-3 {
      border-color: #DD94BE; }
    .feature-button__content--theme-4 {
      border-color: #C2E398; }
  .feature-button__body {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
  .feature-button__footer {
    display: flex;
    justify-content: center; }
  .feature-button__title {
    display: flex;
    color: white;
    text-align: center;
    font-size: 16px;
    line-height: 18px; }
    @media only screen and (min-width: 1400px) {
      .feature-button__title {
        font-size: 20px;
        line-height: 24px; } }
  .feature-button__icon {
    width: 22px;
    height: 22px; }
    @media only screen and (min-width: 1400px) {
      .feature-button__icon {
        width: 32px;
        height: 32px; } }

.gallery__filters {
  display: flex;
  flex-wrap: wrap;
  margin: 1.25rem 0; }

.gallery__filter-button {
  margin: 0 10px 10px 0; }

.gallery__images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.25rem; }
  @media only screen and (min-width: 960px) {
    .gallery__images {
      grid-template-columns: repeat(3, 1fr); } }
  .gallery__images img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border: 1px solid lightgrey;
    padding: 10px; }

.gallery__pagination {
  margin: 1.25rem 0; }

.highlight-card {
  display: flex;
  position: relative;
  height: 100%;
  background-color: black; }
  .highlight-card__main {
    display: flex;
    flex: 1;
    border-width: 10px;
    border-color: transparent;
    border-style: solid;
    padding: 15px; }
    @media only screen and (min-width: 1400px) {
      .highlight-card__main {
        padding: 25px; } }
    .highlight-card__main--primary:hover {
      border-color: #F7D1C4; }
    .highlight-card__main--theme-1:hover {
      border-color: #99D4DC; }
    .highlight-card__main--theme-2:hover {
      border-color: #F07171; }
    .highlight-card__main--theme-3:hover {
      border-color: #DD94BE; }
    .highlight-card__main--theme-4:hover {
      border-color: #C2E398; }
  .highlight-card__media {
    position: absolute;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 0.15; }
  .highlight-card__content {
    display: flex;
    flex: 1;
    position: relative;
    z-index: 2;
    flex-direction: column; }
  .highlight-card__body {
    flex: 1; }
  .highlight-card__title {
    color: #6C6C6C; }
    @media only screen and (min-width: 960px) {
      .highlight-card__title {
        font-size: 1.5em; } }
    @media only screen and (min-width: 1400px) {
      .highlight-card__title {
        font-size: 2em; } }
    .highlight-card__title::after {
      content: '';
      display: block;
      width: 14px;
      height: 5px;
      background-color: #6C6C6C;
      margin-top: 15px;
      transition: width 300ms; }
    .highlight-card__main:hover .highlight-card__title--primary {
      color: #F7D1C4; }
      .highlight-card__main:hover .highlight-card__title--primary::after {
        content: '';
        width: 84px;
        background-color: #F7D1C4; }
    .highlight-card__main:hover .highlight-card__title--theme-1 {
      color: #99D4DC; }
      .highlight-card__main:hover .highlight-card__title--theme-1::after {
        content: '';
        width: 84px;
        background-color: #99D4DC; }
    .highlight-card__main:hover .highlight-card__title--theme-2 {
      color: #F07171; }
      .highlight-card__main:hover .highlight-card__title--theme-2::after {
        content: '';
        width: 84px;
        background-color: #F07171; }
    .highlight-card__main:hover .highlight-card__title--theme-3 {
      color: #DD94BE; }
      .highlight-card__main:hover .highlight-card__title--theme-3::after {
        content: '';
        width: 84px;
        background-color: #DD94BE; }
    .highlight-card__main:hover .highlight-card__title--theme-4 {
      color: #C2E398; }
      .highlight-card__main:hover .highlight-card__title--theme-4::after {
        content: '';
        width: 84px;
        background-color: #C2E398; }
  .highlight-card__description {
    color: #6C6C6C;
    font-size: 0.938em; }
    .highlight-card__main:hover .highlight-card__description--primary {
      color: #F7D1C4; }
    .highlight-card__main:hover .highlight-card__description--theme-1 {
      color: #99D4DC; }
    .highlight-card__main:hover .highlight-card__description--theme-2 {
      color: #F07171; }
    .highlight-card__main:hover .highlight-card__description--theme-3 {
      color: #DD94BE; }
    .highlight-card__main:hover .highlight-card__description--theme-4 {
      color: #C2E398; }
  .highlight-card__icon {
    display: flex;
    justify-content: center;
    color: #6C6C6C; }
    .highlight-card__main:hover .highlight-card__icon--primary {
      color: #F7D1C4; }
    .highlight-card__main:hover .highlight-card__icon--theme-1 {
      color: #99D4DC; }
    .highlight-card__main:hover .highlight-card__icon--theme-2 {
      color: #F07171; }
    .highlight-card__main:hover .highlight-card__icon--theme-3 {
      color: #DD94BE; }
    .highlight-card__main:hover .highlight-card__icon--theme-4 {
      color: #C2E398; }
  .highlight-card__footer {
    margin-top: 2em; }
    .highlight-card__footer--primary {
      color: #F7D1C4; }
    .highlight-card__footer--theme-1 {
      color: #99D4DC; }
    .highlight-card__footer--theme-2 {
      color: #F07171; }
    .highlight-card__footer--theme-3 {
      color: #DD94BE; }
    .highlight-card__footer--theme-4 {
      color: #C2E398; }

.icon {
  margin: 0 .5rem; }

.iframe {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; }
  .iframe > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.image {
  max-width: 100%;
  height: auto;
  margin: 0; }

.jumbotron {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  background-color: #000000;
  background-size: cover;
  background-position: right center;
  border-radius: 4px;
  overflow: hidden; }
  .jumbotron__before img {
    width: 142px;
    height: auto; }
  .jumbotron__content {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 3.125rem 0; }
    @media only screen and (min-width: 1400px) {
      .jumbotron__content {
        padding: 4.375rem 0; } }
  .jumbotron__title {
    color: white;
    text-transform: uppercase;
    font-size: 2.2em;
    font-weight: 100;
    margin: 0; }
  .jumbotron__actions {
    width: 100%; }

.jumbotron-button {
  border-bottom-width: 5px;
  border-bottom-style: solid;
  color: white;
  background-color: none;
  display: inline-block;
  font-size: 1em;
  padding: 0.6rem 0;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: bold; }
  .jumbotron-button:hover {
    opacity: 0.5;
    position: relative;
    top: 1px; }
  .jumbotron-button--primary {
    border-bottom-color: #F7D1C4;
    color: #F7D1C4; }
  .jumbotron-button--theme-1 {
    border-bottom-color: #99D4DC;
    color: #99D4DC; }
  .jumbotron-button--theme-2 {
    border-bottom-color: #F07171;
    color: #F07171; }
  .jumbotron-button--theme-3 {
    border-bottom-color: #DD94BE;
    color: #DD94BE; }
  .jumbotron-button--theme-4 {
    border-bottom-color: #C2E398;
    color: #C2E398; }

.label {
  display: inline-block;
  padding: 5px;
  background-color: grey;
  color: white; }

.lightbox-gallery-active {
  overflow: hidden; }

.lightbox-gallery__container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.8); }
  .lightbox-gallery__container--enter {
    opacity: 0; }
  .lightbox-gallery__container--enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in; }
  .lightbox-gallery__container--leave {
    opacity: 1; }
  .lightbox-gallery__container--leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in; }

.lightbox-gallery__inner {
  height: 100%;
  width: 100%; }

.lightbox-gallery__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem 5rem; }

.lightbox-gallery__image {
  position: relative;
  margin: 0 auto; }
  .lightbox-gallery__image img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 5rem 0;
    margin: 0 auto; }
  .lightbox-gallery__image figcaption {
    margin-top: -4rem;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
    color: white;
    font-size: 14px; }

.lightbox-gallery__iframe {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.lightbox-gallery__text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  background-color: #383838;
  padding: 2rem; }

.lightbox-gallery__button {
  overflow: visible;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: 0;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: white; }

.lightbox-gallery__close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 38px;
  line-height: 38px;
  width: 38px;
  height: 38px; }

.lightbox-gallery__prev, .lightbox-gallery__next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100px;
  height: 80px; }

.lightbox-gallery__prev {
  left: 0; }

.lightbox-gallery__next {
  right: 0; }

@media only screen and (min-width: 960px) {
  .list--grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 1rem; } }

.list__item--row-gap-sm {
  margin-bottom: 1rem; }

.list__item--row-gap-md {
  margin-bottom: 1.2rem; }

.list__item--row-gap-lg {
  margin-bottom: 1.25rem; }

.list__item:last-child {
  margin-bottom: 0; }

@media only screen and (min-width: 960px) {
  .list__item--grid {
    display: flex;
    margin-bottom: 0; } }

.list__item img {
  width: 100%; }

.list-item {
  position: relative;
  background-color: white;
  border: 1px solid lightgrey; }
  @media only screen and (min-width: 960px) {
    .list-item {
      display: flex;
      flex: 1 1 100%;
      flex-wrap: no-wrap;
      align-items: stretch; } }
  .list-item__media {
    width: calc(100% - 50px); }
    @media only screen and (min-width: 960px) {
      .list-item__media {
        flex-basis: 180px;
        min-width: 180px; } }
    .list-item__media img {
      height: 100px;
      width: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover'; }
      @media only screen and (min-width: 960px) {
        .list-item__media img {
          height: 100%;
          width: 180px; } }
  .list-item__body {
    flex: 1;
    flex-basis: auto;
    flex-wrap: wrap;
    padding-right: 50px;
    padding: 1.25rem;
    width: calc(100% - 50px); }
    @media only screen and (min-width: 960px) {
      .list-item__body {
        padding-right: 10px; } }
  .list-item__title:last-child {
    margin-bottom: 0; }
  .list-item__subtitle {
    font-size: 0.938em;
    line-height: 1.4em; }
    .list-item__subtitle p:not(:last-child) {
      margin-bottom: 1rem; }
    .list-item__subtitle ul {
      padding-left: 20px;
      margin-bottom: 2em; }
      .list-item__subtitle ul li {
        list-style-type: disc; }
        .list-item__subtitle ul li:not(:last-child) {
          margin-bottom: 0.5em; }
    .list-item__subtitle ol {
      padding-left: 20px;
      margin-bottom: 2em; }
      .list-item__subtitle ol li {
        font-size: 14px;
        list-style-type: decimal; }
        .list-item__subtitle ol li:not(:last-child) {
          margin-bottom: 0.5em; }
  .list-item__after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    width: 30px; }
    @media only screen and (min-width: 960px) {
      .list-item__after {
        position: relative;
        top: auto;
        transform: none;
        right: auto;
        max-width: 100px;
        display: flex;
        padding: 1.25rem;
        align-items: center;
        justify-content: center;
        flex-basis: 100px;
        justify-self: flex-end; } }
  .list-item__icon {
    color: #000000; }

.loader {
  display: flex;
  position: fixed;
  background-color: #262626;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999999;
  align-items: center;
  justify-content: center; }
  .loader--enter {
    opacity: 0.999; }
  .loader--enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in; }
  .loader--leave {
    opacity: 1; }
  .loader--leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in; }
  .loader__inner {
    display: flex;
    align-items: center;
    flex-direction: column; }
  .loader__text {
    color: #F7D1C4; }
  .loader__spinner {
    color: #F7D1C4; }

.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  padding: 20px;
  overflow: scroll; }
  @media only screen and (min-width: 960px) {
    .modal {
      padding-left: 40px;
      padding-right: 40px;
      padding: 0 40px;
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      overflow: auto; } }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 99999; }

.modal__close {
  position: absolute;
  right: 0px;
  top: 0px;
  border: 0;
  background-color: transparent; }

.nav {
  padding: 1rem; }
  @media only screen and (min-width: 960px) {
    .nav {
      padding: 2rem; } }
  .nav--fixed {
    position: fixed; }
  .nav__item {
    margin-bottom: 0 !important; }
    .nav__item--parent {
      display: none; }
    .nav__item--parent.nav__item--active {
      display: block; }
  .nav__descendant {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden; }
    .nav__descendant--active {
      max-height: 999px;
      transition: max-height 0.25s ease-in; }
  .nav__link {
    display: flex;
    align-items: center;
    position: relative;
    padding: .8rem 1.8rem .8rem .8rem;
    color: #ffffff;
    font-size: 14px;
    line-height: 18px; }
    .nav__item--primary .nav__link--parent.nav__link--active {
      background-color: #F7D1C4;
      color: #262626; }
    .nav__item--theme-1 .nav__link--parent.nav__link--active {
      background-color: #99D4DC;
      color: #262626; }
    .nav__item--theme-2 .nav__link--parent.nav__link--active {
      background-color: #F07171;
      color: #262626; }
    .nav__item--theme-3 .nav__link--parent.nav__link--active {
      background-color: #DD94BE;
      color: #262626; }
    .nav__item--theme-4 .nav__link--parent.nav__link--active {
      background-color: #C2E398;
      color: #262626; }
    .nav__item--primary .nav__link--child.nav__link--active {
      color: #F7D1C4; }
    .nav__item--theme-1 .nav__link--child.nav__link--active {
      color: #99D4DC; }
    .nav__item--theme-2 .nav__link--child.nav__link--active {
      color: #F07171; }
    .nav__item--theme-3 .nav__link--child.nav__link--active {
      color: #DD94BE; }
    .nav__item--theme-4 .nav__link--child.nav__link--active {
      color: #C2E398; }
    .nav__item--primary .nav__link--grandchild.nav__link--active {
      color: #F7D1C4; }
    .nav__item--theme-1 .nav__link--grandchild.nav__link--active {
      color: #99D4DC; }
    .nav__item--theme-2 .nav__link--grandchild.nav__link--active {
      color: #F07171; }
    .nav__item--theme-3 .nav__link--grandchild.nav__link--active {
      color: #DD94BE; }
    .nav__item--theme-4 .nav__link--grandchild.nav__link--active {
      color: #C2E398; }
    .nav__link--parent {
      font-weight: bold;
      margin-bottom: 10px; }
    .nav__link--child {
      font-weight: normal;
      padding: .6rem 1.8rem .6rem .8rem; }
    .nav__link--grandchild {
      padding-left: 2rem; }
    .nav__link--great-grandchild {
      padding-left: 4rem; }
  .nav__icon {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 24px;
    height: auto;
    margin-right: 1rem; }
  .nav__chevron {
    position: absolute;
    right: 0.8rem; }
    .nav__link--active .nav__chevron {
      transform: rotate(90deg); }

.nav-box {
  display: flex;
  flex-direction: column; }
  .nav-box--column {
    flex-direction: row;
    overflow-x: auto; }
  .nav-box__item {
    flex: 1; }
    .nav-box__item:not(:last-child) {
      margin-right: 10%;
      margin-bottom: 0; }

.nav-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px; }
  @media only screen and (min-width: 960px) {
    .nav-grid {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr); } }
  @media only screen and (min-width: 1400px) {
    .nav-grid {
      grid-column-gap: 30px;
      grid-row-gap: 30px; } }

.nav-tab {
  position: relative;
  border: 1px solid #262626;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-right: 5.1rem;
  height: 120px; }
  .nav-tab--primary.nav-tab--active {
    background-color: #F7D1C4; }
  .nav-tab--theme-1.nav-tab--active {
    background-color: #99D4DC; }
  .nav-tab--theme-2.nav-tab--active {
    background-color: #F07171; }
  .nav-tab--theme-3.nav-tab--active {
    background-color: #DD94BE; }
  .nav-tab--theme-4.nav-tab--active {
    background-color: #C2E398; }
  .nav-tab__title::after {
    content: '';
    display: block;
    width: 40px;
    height: 5px;
    background-color: #262626;
    margin-top: 4px; }
  .nav-tab__icon {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    color: #262626; }
    .nav-tab__icon--active {
      transform: rotate(90deg); }

@media only screen and (min-width: 1400px) {
  .nav-tabs {
    display: flex;
    flex-direction: row; } }

.nav-tabs__item {
  flex: 1;
  padding-bottom: 1rem; }
  @media only screen and (min-width: 1400px) {
    .nav-tabs__item {
      margin-bottom: 0; } }
  @media only screen and (min-width: 1400px) {
    .nav-tabs__item:not(:last-child) {
      margin-right: 1.25rem; } }
  .nav-tabs__item > * {
    min-height: 100%; }

.nav-tile__main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  text-align: center; }

.nav-tile__image {
  min-height: 1px; }
  .nav-tile__image img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    margin-bottom: .5rem; }

.nav-tile__title {
  color: #ffffff;
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 1rem; }
  .nav-tile__title--small {
    font-size: .7rem; }

.navbar {
  background-color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
  color: #ffffff;
  height: 4rem; }
  @media only screen and (min-width: 1400px) {
    .navbar {
      height: 4.2rem; } }
  .navbar__title img {
    height: 2.66667rem;
    width: auto; }
    @media only screen and (min-width: 1400px) {
      .navbar__title img {
        height: 2.8rem; } }
  .navbar__before {
    width: 200px; }
  .navbar__after {
    width: 200px; }

.navbar-button {
  display: inline-block;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  vertical-align: middle;
  margin-left: 1rem; }
  .navbar-button svg {
    position: relative;
    vertical-align: sub;
    width: 1rem;
    height: 1rem; }

.pagination__list {
  display: flex;
  justify-content: center;
  margin-top: 1rem; }

.pagination__item {
  margin: 0 0.5rem; }

.pagination__button {
  display: block;
  width: 30px;
  height: 30px;
  background-color: lightgrey;
  color: #6C6C6C;
  border: 0; }
  .pagination__button--active {
    background-color: #6C6C6C;
    color: white; }

.panel {
  padding: 1.25rem; }
  .panel + .panel {
    padding-top: 0; }
  .panel--default {
    background-color: #ffffff; }
  .panel--welcome {
    padding: 0; }
  .panel--primary {
    background: #F7D1C4; }
  .panel--theme-1 {
    background: #99D4DC; }
  .panel--theme-2 {
    background: #F07171; }
  .panel--theme-3 {
    background: #DD94BE; }
  .panel--theme-4 {
    background: #C2E398; }
  .panel__body--welcome {
    color: #fff;
    font-size: 130%; }
  .panel__body p:not(:last-child) {
    margin-bottom: 1rem; }
  .panel__body ul {
    padding-left: 20px;
    margin-bottom: 2em; }
    .panel__body ul li {
      list-style-type: disc; }
      .panel__body ul li:not(:last-child) {
        margin-bottom: 0.5em; }
  .panel__body ol {
    padding-left: 20px;
    margin-bottom: 2em; }
    .panel__body ol li {
      font-size: 14px;
      list-style-type: decimal; }
      .panel__body ol li:not(:last-child) {
        margin-bottom: 0.5em; }
  .panel__body img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 0 2em; }
  .panel__body + .panel__children {
    margin-top: 2em; }

.panel-tabs {
  -webkit-tap-highlight-color: transparent; }
  .panel-tabs__tab-list {
    display: flex;
    flex-direction: row;
    padding: 0; }
  .panel-tabs__tab {
    flex: 1;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 2rem 1.5rem;
    cursor: pointer;
    background-color: lightgrey;
    box-shadow: inset 0 0 2px #262626;
    z-index: 1; }
    .panel-tabs__tab--selected {
      box-shadow: none;
      z-index: 0;
      background: #fff;
      color: black; }
    .panel-tabs__tab--disabled {
      color: GrayText;
      cursor: default; }
    .panel-tabs__tab:focus {
      box-shadow: 0 0 5px #0188fe;
      border-color: #0188fe;
      outline: none; }
  .panel-tabs__tab-text {
    font-size: 1.500em;
    font-weight: 100;
    margin-bottom: 0; }
    .panel-tabs__tab--selected .panel-tabs__tab-text::after {
      content: '';
      display: block;
      width: 40px;
      height: 5px;
      background-color: #262626;
      margin-top: 4px; }
  .panel-tabs__tab-panel {
    display: none; }
    .panel-tabs__tab-panel--selected {
      display: block; }

.poster {
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  position: relative; }
  .poster__background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .poster__main {
    display: flex;
    position: relative;
    flex: 1; }
    .poster__main--row {
      min-height: 100px; }
      @media only screen and (min-width: 1400px) {
        .poster__main--row {
          min-height: 200px; } }
    .poster__main--column {
      justify-content: flex-end; }
  .poster__content {
    padding: 1.25rem;
    display: flex;
    flex-direction: column; }
    .poster__content--small {
      padding: 1.25rem; }
    .poster__content--row {
      flex: 1; }
    .poster__content--column {
      flex: .5; }
    .poster__content--overlay {
      background-color: rgba(0, 0, 0, 0.8); }
  .poster__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem; }
  .poster__actions {
    margin-top: 1rem;
    padding: 24px;
    margin: 0 -24px -24px;
    border-radius: 0 0 5px 5px; }
    .poster__actions--overlay {
      background: #1d1d1d; }
    .poster__actions > *:not(:last-child) {
      margin-right: 1.25rem; }
  .poster__title {
    font-size: 1.25em;
    font-weight: 100;
    color: #fff;
    margin-bottom: 0.5rem; }
    .poster__title--overlay {
      color: #F7D1C4; }
  .poster__headline {
    font-size: .875em;
    line-height: 1.5em;
    font-weight: 400;
    color: #F7D1C4;
    margin-top: 1rem;
    margin-bottom: 1rem; }
    .poster__headline:last-child {
      margin: 0; }
    .poster__headline--small {
      font-size: 1.250em;
      line-height: 1.2em; }
  .poster__text {
    flex: 1;
    color: #ffffff; }
  .poster__link {
    align-self: flex-end; }

.profile__image {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 100px;
  overflow: hidden; }
  @media only screen and (min-width: 960px) {
    .profile__image {
      height: 140px;
      width: 140px; } }

.profile__title {
  width: 80px;
  text-align: center;
  margin-top: 10px; }
  .profile__title--dark {
    color: #fff; }
  @media only screen and (min-width: 960px) {
    .profile__title {
      width: 140px; } }

.react-tooltip-lite {
  background: #1d1d1d;
  width: 300px !important;
  color: #fff;
  font-size: .875em;
  line-height: 1.5;
  border-radius: 4px; }

.react-tooltip-lite-arrow {
  border-color: #1d1d1d; }

.search-box {
  background-color: white; }
  .search-box__form {
    display: flex; }
  .search-box__input {
    flex: 1;
    border: none;
    background-color: transparent;
    padding: .5rem;
    line-height: 24px;
    font-size: 0.938em; }
  .search-box__button {
    vertical-align: middle;
    padding: .5rem;
    margin: 0;
    line-height: 0;
    font-size: 0;
    background-color: transparent;
    border: 0; }

.title {
  color: #ffffff; }
  .title--h1 {
    display: inline-block;
    font-weight: 100;
    font-size: 2.2em;
    max-width: 600px;
    padding: 0 4rem .5rem 0;
    margin-bottom: 2rem; }
  .title--h2 {
    display: inline-block;
    font-weight: 700;
    font-size: 2em;
    max-width: 600px;
    padding: 0 4rem .5rem 0;
    margin-bottom: 2rem; }
  .title--h1::after, .title--h2::after {
    content: '';
    display: block;
    width: 78px;
    height: 5px;
    background-color: white;
    margin-top: 15px; }
  .title--h5 {
    font-size: 0.75rem;
    margin-bottom: 1rem;
    text-transform: uppercase; }
  .title--inverted {
    color: #000000; }
  .title--full-width {
    max-width: 100%; }
  .title--primary {
    color: #F7D1C4; }
    .title--primary::after {
      background-color: #F7D1C4; }
  .title--theme-1 {
    color: #99D4DC; }
    .title--theme-1::after {
      background-color: #99D4DC; }
  .title--theme-2 {
    color: #F07171; }
    .title--theme-2::after {
      background-color: #F07171; }
  .title--theme-3 {
    color: #DD94BE; }
    .title--theme-3::after {
      background-color: #DD94BE; }
  .title--theme-4 {
    color: #C2E398; }
    .title--theme-4::after {
      background-color: #C2E398; }

.title-button {
  display: block;
  position: relative;
  width: 100%;
  height: 80px; }
  .title-button--primary {
    background-color: #F7D1C4; }
  .title-button--theme-1 {
    background-color: #99D4DC; }
  .title-button--theme-2 {
    background-color: #F07171; }
  .title-button--theme-3 {
    background-color: #DD94BE; }
  .title-button--theme-4 {
    background-color: #C2E398; }
  .title-button__inner {
    position: absolute;
    bottom: 0;
    font-weight: 300;
    font-size: 1.1em;
    padding: 10px 80px 10px 10px; }
    .title-button__inner::after {
      content: '';
      display: block;
      width: 50px;
      height: 5px;
      background-color: #000;
      margin-top: 8px; }

.tools-menu {
  vertical-align: middle;
  display: inline-block; }
  .tools-menu__button {
    cursor: pointer;
    float: left; }
  .tools-menu__panel {
    position: absolute;
    width: 250px;
    background-color: white;
    left: 10px;
    flex-wrap: wrap;
    padding: 10px;
    border: 1px solid #383838;
    align-items: flex-start;
    margin-top: 35px;
    display: none; }
    .tools-menu__panel--visible {
      display: flex; }
      .tools-menu__panel--visible::before {
        position: absolute;
        top: -8px;
        left: 13px;
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 12px 15px 12px;
        border-color: transparent transparent #ffffff transparent; }
  .tools-menu__item {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    flex-wrap: wrap;
    border: 1px solid transparent; }
    .tools-menu__item:hover {
      border: 1px solid #333; }
  .tools-menu__group {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #1D1D1D;
    display: flex;
    align-items: center;
    justify-content: center; }
  .tools-menu__icon {
    width: 100%;
    max-width: 30px; }
  .tools-menu__label {
    color: #786c6c;
    line-height: 1 !important;
    display: inline-block;
    text-decoration: none;
    font-size: 11px;
    margin-top: 5px; }

.yammer {
  background: #1d1d1d;
  border-radius: 4px;
  overflow: hidden; }

body {
  background-color: #262626; }
