.panel {
    $this: &;

    padding: map-get($default-spacing, 'desktop');

    +#{$this} {
        padding-top: 0;
    }

    &--default {
        background-color: map-get($default-colours, white);
    }

    &--welcome {
        padding: 0;
    }

    @each $theme,
    $color in $theme-colours {
        &--#{$theme} {
            background: #{$color};
        }
    }

    &__body {
        &--welcome {
            color: #fff;
            font-size: 130%;
        }


        p {
            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }

        ul {
            padding-left: 20px;
            margin-bottom: 2em;

            li {
                list-style-type: disc;

                &:not(:last-child) {
                    margin-bottom: 0.5em;
                }
            }
        }

        ol {
            padding-left: 20px;
            margin-bottom: 2em;

            li {
                font-size: 14px;
                list-style-type: decimal;

                &:not(:last-child) {
                    margin-bottom: 0.5em;
                }
            }
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 0 2em;
        }

        +#{$this}__children {
            margin-top: 2em;
        }
    }
}