.jumbotron-button {
    $this: &;

    border-bottom-width: 5px;
    border-bottom-style: solid;
    color: white;
    background-color: none;
    display: inline-block;
    font-size: 1em;
    padding: 0.6rem 0;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: bold;

    &:hover {
        opacity: 0.5;
        position: relative;
        top: 1px;
    }

    @each $theme,
    $color in $theme-colours {
        &--#{$theme} {
            border-bottom-color: #{$color};
            color: #{$color};
        }
    }
}