// Font weights
$light: 100;
$regular: 400;
$bold: 600;

$base-font-family: sans-serif;
$base-font-weight: $regular;
$base-font-size: 13px;
$base-line-height: 1.4;

$fixed-font-family: monospace;
$fixed-font-size: 85%;
$fixed-line-height: $base-line-height;

$border-radius: 4px;

$header-font-weight: $bold;

$default-colours: (
	primary: #F7D1C4,
	black: #000000,
	white: #ffffff,
	grey-xlight: lightgrey,
	grey-light: #6C6C6C,
	grey-base: #383838,
	grey-dark: #262626,
);

$theme-colours: (
	primary: #F7D1C4,
	theme-1: #99D4DC,
	theme-2: #F07171,
	theme-3: #DD94BE,
	theme-4: #C2E398,
);

$breakpoints: (
	"mobile": 768px,
	"tablet": 960px,
	"desktop": 1400px,
);

$default-spacing: (
	mobile: 0.8rem,
	tablet: 1rem,
	desktop: 1.25rem,
);

$navbar-height: (
	"tablet": 4rem,
	"desktop": 4.2rem,
);

// $dashboard-grid-gap: (
// 	"tablet": 
// )