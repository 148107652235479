.list {
    &--grid {
        @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto;
            grid-gap: map-get($default-spacing, 'tablet');
        }
    }

    &__item {
        &--row-gap-sm {
            margin-bottom: 1rem;
        }

        &--row-gap-md {
            margin-bottom: 1.2rem;
        }

        &--row-gap-lg {
            margin-bottom: map-get($default-spacing, 'desktop');
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--grid {
            @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
                display: flex;
                margin-bottom: 0;
            }
        }

        img {
            width: 100%;
        }

    }

    // &__pagination {
    //     margin: map-get($default-spacing, 'desktop') 0;
    // }
}