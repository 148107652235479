.response-container {
    width: 100%;
    height: 100%;
    padding-top: 56%;
    position: relative;

    * {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}