.breadcrumbs {
    margin-bottom: 2em;

    &__list {}

    &__item {
        display: inline-block;

        &:not(:last-child) {
            &::after {
                content: '//';
                margin: 0 1em;
                color: #9e9e9e;
            }
        }
    }

    &__link {
        color: #9e9e9e;
    }
}