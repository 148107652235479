.page {
    display: flex;
    //grid-template-columns: auto;
    min-height: calc(100vh - #{map-get($navbar-height, 'tablet')});

    &--withnav {
        //@media only screen and (min-width: map-get($breakpoints, 'desktop')) {
        //grid-template-columns: 18.75rem auto;
        //}
    }

    &__nav {
        //display:none;
        background-color: #333333;
        display: none;

        @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
            display: flex;
            flex-basis: 300px;
        }
    }

    &__nav-mobile {
        background-color: #333333;
        width: 300px;
    }

    &__main {
        flex: 1;
    }

    &__main-inner {
        padding: 1em;
        margin: 0 auto;
        max-width: 60rem;

        @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
            padding: 2em;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-areas:
                'breadcrumbs'
                'header'
                'header'
                'article'
                'footer';
        }

        &--withsidebar {
            display: grid;
            grid-template-areas:
                'breadcrumbs'
                'header'
                'article'
                'footer'
                'sidebar'
            ;
            grid-column-gap: map-get($default-spacing, 'desktop');
            grid-template-columns: 1fr;
            grid-row-gap: map-get($default-spacing, 'tablet');

            @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
                max-width: 80rem;
                grid-template-areas:
                    'breadcrumbs breadcrumbs'
                    'header header'
                    'article sidebar'
                    'footer sidebar';
                grid-template-columns: 1fr 300px;
            }
        }
    }

    &__breadcrumbs {
        grid-area: breadcrumbs;
    }

    &__header {
        grid-area: header;
    }

    &__article {
        grid-area: article;
    }

    &__sidebar {
        grid-area: sidebar;

        >* {
            &:not(:last-child) {
                margin-bottom: map-get($default-spacing, 'desktop');
            }
        }
    }

    &__footer {
        grid-area: footer;
    }
}


// .page {
//     display: grid;
//     grid-template-columns: auto;
//     min-height: calc(100vh - #{map-get($navbar-height, 'tablet')});
//     height: 100px;

//     &--withnav {
//         //@media only screen and (min-width: map-get($breakpoints, 'desktop')) {
//             grid-template-columns: 18.75rem auto;
//         //}
//     }

//     &__nav {
//         //display:none;
//         background-color: #333333;

//         //@media only screen and (min-width: map-get($breakpoints, 'desktop')) {
//             display: block;
//         //}
//     }

//     &__main {
//         display: inline-grid;
//         grid-template-columns: minmax( auto, 850px );
//         margin: 0 auto;

//         &--withsidebar {
//             grid-template-columns: minmax( auto, 1150px);
//             //grid-template-columns: minmax( auto, calc(18.75rem + 850px));
//         }
//     }

//     &__main-inner {
//         display: inline-grid;
//         grid-template-columns: minmax(300px, 1fr) auto;
//         grid-auto-rows: min-content;
//         padding: 2em;
//         grid-template-areas:
//             'breadcrumbs breadcrumbs'
//             'header header'
//             'article sidebar'
//             'footer sidebar';

//         &--withsidebar {
//             grid-column-gap: 2.5rem;
//         }
//     }

//     &__breadcrumbs {
//         grid-area: breadcrumbs;
//     }

//     &__header {
//         grid-area: header;
//     }

//     &__article {
//         grid-area: article;
//         grid-auto-rows: min-content;
//         display: inline-grid;
//     }

//     &__sidebar {
//         display: inline-grid;
//         grid-area: sidebar;
//         grid-row-gap: 2.5rem;

//         @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
//             width: 300px;
//             grid-auto-rows: min-content;
//             min-height: 0;
//         }
//     }

//     &__footer {
//         grid-area: footer;
//         grid-auto-rows: min-content;
//         display: inline-grid;
//     }
// }