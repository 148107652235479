.feature-button {
    background-size: cover;
    position: relative;
    padding-top: 76%;

    &__main {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.8);

        &:hover {
            background-color: rgba(0,0,0,0.6);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 20px 5px;
        border-width: 6px;
        border-style: solid;

        @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
            padding: 24px;
        }

        @each $theme,
        $color in $theme-colours {
            &--#{$theme} {
                border-color: #{$color};
            }
        }
    }

    &__body {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__footer {
        display: flex;
        justify-content: center;
    }

    &__title {
        display: flex;
        color: white;
        text-align: center;
        font-size: 16px;
        line-height: 18px;

        @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    &__icon {
        width: 22px;
        height: 22px;

        @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
            width: 32px;
            height: 32px;
        }  
    }
}