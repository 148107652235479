.dashboard {
    display: grid;
    margin: auto;
    max-width: 90rem;
    padding: map-get($default-spacing, 'tablet');
    grid-row-gap: map-get($default-spacing, 'tablet');
    grid-column-gap: map-get($default-spacing, 'tablet');
    grid-template-areas:
        'Zone1'
        'Zone2'
        'Zone3'
        'Zone4';

    @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
        grid-template-areas:
            'Zone1 Zone1 Zone1 Zone2'
            'Zone3 Zone3 Zone3 Zone3'
            'Zone4 Zone4 Zone4 Zone4';

        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: map-get($default-spacing, 'desktop');
        grid-row-gap: map-get($default-spacing, 'desktop');
        grid-column-gap: map-get($default-spacing, 'desktop');
    }

    &__zone {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        &--1 {
            grid-area: Zone1;
        }

        &--2 {
            grid-area: Zone4;
            @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
                grid-area: Zone2;
            }
        }

        &--3 {
            grid-area: Zone3;
        }

        &--4 {
            grid-area: Zone2;
            @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
                grid-area: Zone4;
            }
        }

        &--3, &--4 {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            grid-column-gap: map-get($default-spacing, 'tablet');
            grid-row-gap: map-get($default-spacing, 'tablet');

            @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-column-gap: map-get($default-spacing, 'desktop');
            }
        }
    }

    // &__zone-header {}

    // &__zone-body {
    //     display: inline-grid;
    //     grid-row-gap: map-get($default-spacing, 'tablet');

    //     @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
    //         grid-row-gap: map-get($default-spacing, 'desktop');
    //     }

    //     &--1 {}
    //     &--2 {}
    //     &--3 {}
    //     &--4 {}
    //     &--5 {}
    //     &--6 {}
    // }
}

// .dashboard {
//     margin: auto;
//     max-width: 90rem;
//     padding: map-get($default-spacing, 'tablet');


//     &__group {
//         display: flex;
//         flex-direction: row;

//         &:not(:last-child) {
//             margin-bottom: map-get($default-spacing, 'desktop');
//         }

//         &--1 {

//         }
//         &--2 {

//         }
//     }

//     &__column {
//         display: flex;
//         flex-grow: 1;
//         flex-basis: 0;

//         flex-direction: row;

//         @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
//             flex-direction: column;
//         }

//         &:not(:last-child) {
//             margin-right: map-get($default-spacing, 'desktop');
//         }

//         &--1 {
//             @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
//                 flex: 2 1 0;
//             }
//         }
//         &--2 {
//             @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
//                 flex-basis: fit-content;
//             }
//         }
//     }

//     &__zone {
//         display: flex;
//         flex-direction: column;
//         flex: 1;

//         &:not(:last-child) {
//             margin-bottom: map-get($default-spacing, 'desktop');
//         }

//         > * {
//             flex: 1;
//             &:not(:last-child) {
//                 margin-bottom: map-get($default-spacing, 'desktop');
//             }
//         }

//         &--2 {
//             flex: 0 auto;
//         }

//     }
// }