.expand-button {
    padding: 10px;
    background-color: map-get($default-colours, primary);
    border: 0;
    margin: 0;
    line-height: 0;
    
    > .MuiSvgIcon-root {
        width: 38px;
        height: 38px;
    }
}