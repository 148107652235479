.checkbox {
    font-size: 0.938em;

    &__hidden {
        border: 0;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    &__icon {
        background: white;
        border-radius: 3px;
        display: inline-block;
        height: 16px;
        position: relative;
        top: -1px;
        transition: all 150ms;
        vertical-align: middle;
        width: 16px;


        // ${HiddenCheckbox}:focus + & {
        //   box-shadow: 0 0 0 3px pink;
        // }

        svg {
            visibility: hidden;
        }

        &--checked {
            svg {
                visibility: visible;
            }
        }
    }

    &__label {
        margin-left: 0.5rem;
    }
}