.profile {
    &__image {
        height: 80px;
        width: 80px;
        object-fit: cover;
        border-radius: 100px;
        overflow: hidden;

        @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
            height: 140px;
            width: 140px;
        }
    }

    &__title {
        &--dark {
            color: #fff
        }

        width: 80px;
        text-align: center;
        margin-top: 10px;

        @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
            width: 140px;
        }

    }
}