.nav {
    $this: &;
    padding: 1rem;

    @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
        padding: 2rem;
    }

    &--fixed {
        position: fixed;
    }

    &__item {
        margin-bottom: 0 !important;

        &--parent {
            display: none;
        }

        &--parent#{&}--active {
            display: block;
        }
    }

    &__descendant {
        max-height: 0;
        transition: max-height 0.15s ease-out;
        overflow: hidden;

        &--active {
            max-height: 999px;
            transition: max-height 0.25s ease-in;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        position: relative;
        padding: .8rem 1.8rem .8rem .8rem;
        color: map-get($default-colours, white);
        font-size: 14px;
        line-height: 18px;

        &--parent#{&}--active {
            @each $theme,
            $color in $theme-colours {
                #{$this}__item--#{$theme} & {
                    background-color: #{$color};
                    color: map-get($default-colours, grey-dark);
                }
            }
        }

        &--child#{&}--active {

            @each $theme,
            $color in $theme-colours {
                #{$this}__item--#{$theme} & {
                    color: #{$color};
                }
            }
        }

        &--grandchild#{&}--active {

            @each $theme,
            $color in $theme-colours {
                #{$this}__item--#{$theme} & {
                    color: #{$color};
                }
            }
        }

        &--parent {
            font-weight: bold;
            margin-bottom: 10px;
        }

        &--child {
            font-weight: normal;
            padding: .6rem 1.8rem .6rem .8rem;
        }

        &--grandchild {
            padding-left: 2rem;
        }

        &--great-grandchild {
            padding-left: 4rem;
        }
    }

    &__icon {
        display: inline-block;
        position: relative;
        top: -1px;
        width: 24px;
        height: auto;
        margin-right: 1rem;
    }

    &__chevron {
        position: absolute;
        right: 0.8rem;

        #{$this}__link--active & {
            transform: rotate(90deg);
        }
    }
}