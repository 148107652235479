.title {
    color: map-get($default-colours, white);

    &--h1 {
        display: inline-block;
        font-weight: 100;
        font-size: 2.2em;
        max-width: 600px;
        padding: 0 4rem .5rem 0;
        margin-bottom: 2rem;
    }

    &--h2 {
        display: inline-block;
        font-weight: 700;
        font-size: 2em;
        max-width: 600px;
        padding: 0 4rem .5rem 0;
        margin-bottom: 2rem;
    }


    &--h1, &--h2 {
        &::after {
            content: '';
            display: block;
            width: 78px;
            height: 5px;
            background-color: white;
            margin-top: 15px;
        }
    }

    // &--h2 {}
    // &--h3 {}
    // &--h4 {}
    &--h5 {
        font-size: 0.75rem;
        margin-bottom: 1rem;
        text-transform: uppercase;
    }

    &--inverted {
        color: map-get($default-colours, black);
    }

    &--full-width {
        max-width: 100%;
    }

    @each $theme,
    $color in $theme-colours {
        &--#{$theme} {
            color: #{$color};

            &::after {
                background-color: #{$color};
            }
        }
    }
}