.navbar-button {
    display: inline-block;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    white-space: nowrap;
    border-radius: $border-radius;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1rem;
    vertical-align: middle;
    margin-left: 1rem;

    // * {
    //     vertical-align: sub;
    // }
    svg {
        position: relative;
        //top: 2px;
        vertical-align: sub;
        width: 1rem;
        height: 1rem;
    }
}