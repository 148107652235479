.list-item {
    position: relative;
    background-color: white;
    border: 1px solid map-get($default-colours, grey-xlight);

    @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
        display: flex;
        flex: 1 1 100%;
        flex-wrap: no-wrap;
        align-items: stretch;
    }

    &__media {
        width: calc(100% - 50px);
        
        @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
            flex-basis: 180px;
            min-width: 180px;
        }

        img {
            height: 100px;
            width: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover';
            
            @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
                height: 100%;
                width: 180px;
            }
        }
    }

    &__body {
        flex: 1;
        flex-basis: auto;
        flex-wrap: wrap;
        padding-right: 50px;
        padding: map-get($default-spacing, 'desktop');
        width: calc(100% - 50px);

        @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
            padding-right: 10px;
        }
    }

    &__title {
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__subtitle {
        font-size: 0.938em;
        line-height: 1.4em;

        p {
            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }

        ul {
            padding-left: 20px;
            margin-bottom: 2em;

            li {
                list-style-type: disc;

                &:not(:last-child) {
                    margin-bottom: 0.5em;
                }
            }
        }

        ol {
            padding-left: 20px;
            margin-bottom: 2em;

            li {
                font-size: 14px;
                list-style-type: decimal;

                &:not(:last-child) {
                    margin-bottom: 0.5em;
                }
            }
        }
    }

    &__after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        width: 30px;

        @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
            position: relative;
            top: auto;
            transform: none;
            right: auto;
            max-width: 100px;
            display: flex;
            padding: map-get($default-spacing, 'desktop');
            align-items: center;
            justify-content: center;
            flex-basis: 100px;
            justify-self: flex-end;
        }
    }

    &__icon {
        color: map-get($default-colours, black);
    }
}