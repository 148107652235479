@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../assets/fonts/HelveticaNeueLTStd-Th.woff2') format('woff2'),
        url('../assets/fonts/HelveticaNeueLTStd-Th.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../assets/fonts/HelveticaNeueLTStd-Bd.woff2') format('woff2'),
        url('../assets/fonts/HelveticaNeueLTStd-Bd.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../assets/fonts/HelveticaNeueLTStd-Roman.woff2') format('woff2'),
        url('../assets/fonts/HelveticaNeueLTStd-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html {
    font-size: 85%;

    @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
        font-size: 100%;
    }
}

body {
    font-family: 'Helvetica Neue LT Std', sans-serif;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
}

h1 {
    font-size: 3.25em;
    font-weight: 100;
}

h2 {
    font-size: 1.75em;
    font-weight: 100;
}

h3 {
    font-size: 1.375em;
    line-height: 26px;
    font-weight: 100;
}

h4 {
    font-size: 1.25em;
    font-weight: 100;
    line-height: 28px;
}

h5 {
    font-size: 1em;
    font-weight: bold;
    line-height: 24px;
}

h6 {
    font-size: .938em;
    font-weight: bold;
    line-height: 20px;
}

// h5 {

// }

// h6 {

// }

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.5em;
}

p, li {
    font-size: .875em;
    line-height: 1.5em;

    strong {
        font-weight: bold;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

button:focus {
    outline: none;
}