.gallery {
    &__subtitle {}

    &__filters {
        display: flex;
        flex-wrap: wrap;
        margin: map-get($default-spacing, 'desktop') 0;
    }

    &__filter-button {
        margin: 0 10px 10px 0;

    }

    &__images {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: map-get($default-spacing, 'desktop');

        @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
            grid-template-columns: repeat(3, 1fr);
        }


        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border: 1px solid map-get($default-colours, grey-xlight);
            padding: 10px;
        }
    }

    &__pagination {
        margin: map-get($default-spacing, 'desktop') 0;
    }
}