.navbar {
    background-color: map-get($default-colours, black);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 map-get($default-spacing, 'desktop');
    color: map-get($default-colours, white);
    height: map-get($navbar-height, 'tablet');

    @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
        height: map-get($navbar-height, 'desktop');
    }

    &__title {
        img {
            height: map-get($navbar-height, 'tablet') / 1.5;
            width: auto;

            @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
                height: map-get($navbar-height, 'desktop') / 1.5;
            }
        }
    }

    &__before {
        width: 200px;
    }

    &__after {
        width: 200px;
    }
}