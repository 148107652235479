.content-modal {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box;

    &::before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    
    &__overlay {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1043;
        position: fixed;
        outline: 0!important;
        overflow: hidden auto;
        background-color: rgba(0,0,0,0.8);
    }

    &__inner {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin: 0 auto;
        text-align: left;
        z-index: 1045;
    }

    &__content {
        position: relative;
        max-width: 800px;
        width: 100%;
        background-color: white;

        &--large {
            max-width: 1400px,
        }
    }

    &__close {
        position: absolute;
        right: 0;
        top: 0;
        border: 0;
        padding: 20px;
        font-size: 28px;
        line-height: 16px;
        background-color: transparent;
    }
}

.ReactModal__Body--open {
    overflow-y: hidden;
}