.modal {
    position: absolute;
    top: 0;
    bottom:0;
    width: 100%;
    max-width: 100%;
    padding: 20px;
    overflow: scroll;

    @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
      padding-left: 40px;
      padding-right: 40px;
      padding: 0 40px;
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      overflow: auto;
    }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.95);
  z-index: 99999;
}

.modal__close {
  position: absolute;
  right: 0px;
  top: 0px;
  border: 0;
  background-color: transparent;
}