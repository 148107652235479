.react-tooltip-lite {
	background: #1d1d1d;
	width: 300px !important;
	color: #fff;
	font-size: .875em;
	line-height: 1.5;
	border-radius: 4px;
}

.react-tooltip-lite-arrow {
  border-color: #1d1d1d;
}