.title-button {
    display: block;
    position: relative;
    width: 100%;
    height: 80px;
    

    @each $theme,
    $color in $theme-colours {
        &--#{$theme} {
            background-color: #{$color};

        }
    }

    &__inner {
        position: absolute;
        bottom: 0;
        font-weight: 300;
        font-size: 1.1em;
        padding: 10px 80px 10px 10px;

        &::after {
            content: '';
            display: block;
            width: 50px;
            height: 5px;
            background-color: #000;
            margin-top: 8px;
        }
    }
}