.nav-tab {
    $this: &;

    position: relative;
    border: 1px solid map-get($default-colours, grey-dark);
    padding-top: map-get($default-spacing, 'tablet');
    padding-left: map-get($default-spacing, 'tablet');
    padding-bottom: map-get($default-spacing, 'tablet');
    padding-right: 5.1rem;
    height: 120px;

    @each $theme,
    $color in $theme-colours {
        &--#{$theme} {
            &#{$this}--active {
                background-color: #{$color};
            }
        }
    }

    &__title {
        &::after {
            content: '';
            display: block;
            width: 40px;
            height: 5px;
            background-color: map-get($default-colours, grey-dark);
            margin-top: 4px;
        }
    }

    &__icon {
        position: absolute;
        right: map-get($default-spacing, 'tablet');
        bottom: map-get($default-spacing, 'tablet');
        color: map-get($default-colours, grey-dark);

        &--active {
            transform: rotate(90deg);
        }
    }


}