.highlight-card {
    $this: &;

    display: flex;
    position: relative;
    height: 100%;
    background-color: black;

    // @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
    //     min-height: 20vh;    
    // }

    // @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
    //     min-height: 30vh;    
    // }

    &__main {
        $main: &;

        display: flex;
        flex: 1;
        border-width: 10px;
        border-color: transparent;
        border-style: solid;
        padding: 15px;

        @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
            padding: 25px;
        }

        @each $theme,
        $color in $theme-colours {
            &--#{$theme} {
                &:hover {
                    border-color: #{$color};
                }
            }
        }           
        
    }
   
    &__media { 
        position: absolute;
        background-size: cover;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        opacity: 0.15;
    }

    &__content {
        display: flex;
        flex: 1;
        position: relative;
        z-index: 2;
        flex-direction: column;
    }

    &__body {
        flex: 1;
    }

    &__title {
        color: map-get($default-colours, grey-light);

        @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
            font-size: 1.5em;
        }
    
        @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
            font-size: 2em;  
        }
        

        &::after {
            content: '';
            display: block;
            width: 14px;
            height: 5px;
            background-color: map-get($default-colours, grey-light);
            margin-top: 15px;
            transition: width 300ms;
        }

        @each $theme,
        $color in $theme-colours {
            &--#{$theme} {
                #{$this}__main:hover & {
                    color: #{$color};

                    &::after {
                        content: '';
                        width: 84px;
                        background-color: $color;

                    }
                }
            }
        }

    }

    &__description {
        color: map-get($default-colours, grey-light);
        font-size: 0.938em;

        @each $theme,
        $color in $theme-colours {
            &--#{$theme} {
                #{$this}__main:hover & {
                    color: #{$color};
                }
            }
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        color: map-get($default-colours, grey-light);

        @each $theme,
        $color in $theme-colours {
            &--#{$theme} {
                #{$this}__main:hover & {
                    color: #{$color};
                }
            }
        }
    }

    &__footer {
        margin-top: 2em;

        @each $theme,
        $color in $theme-colours {
            &--#{$theme} {
                color: #{$color};
            }
        }
    }


}