.nav-tile {
    $this: &;

    &__main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
        text-align: center;
    }

    &__image {
        min-height: 1px;

        img {
            display: inline-block;
            max-width: 100%;
            height: auto;
            margin-bottom: .5rem;
        }
    }

    &__title {
        color: map-get($default-colours, white);
        font-size: 0.75rem;
        text-transform: uppercase;
        line-height: 1rem;

        &--small {
            font-size: .7rem;
        }
    }
}