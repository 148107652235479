.nav-tabs {
    $this: &;

    
    //flex-direction: column;

    @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
        display: flex;
        flex-direction: row;
    }

    &__item {
        flex: 1;

        padding-bottom: map-get($default-spacing, 'tablet');

        @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
            margin-bottom: 0;
        }

        &:not(:last-child) {
            @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
                margin-right: map-get($default-spacing, 'desktop');
            }
        }

        > * {
            min-height: 100%;
        }
    }
}