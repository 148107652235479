.content-modal-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
        grid-column-gap: 30px;
        grid-row-gap: 30px;
    }
}