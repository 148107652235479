.button {
    $this: &;

    display: inline-block;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    border-radius: $border-radius;
    text-transform: uppercase;
    //font-weight: n;

    // ripple
    background-position: center;
    transition: background 0.8s;

    &:hover {
        // ripple
        //background: black radial-gradient(circle, transparent 1%, black 1%) center/15000%;
    }

    &:active {
        // background-color: map-get($default-colours, grey-dark);
        // background-size: 100%;
        // transition: background 0s;
    }

    // Size
    &--small {}

    &--medium {
        padding: 0.8rem 1rem;
        font-size: 1rem;

        @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
            padding: 0.4rem 0.6rem;
            font-size: 0.75rem;
        }
    }

    &--large {}

    // Mode
    &--dark {
        background-color: map-get($default-colours, grey-dark);
        color: map-get($default-colours, white);

        // Dark and outline
        &#{$this}--outline {
            color: map-get($default-colours, grey-dark);
        }
    }

    &--light {
        background-color: map-get($default-colours, primary);
        color: map-get($default-colours, grey-dark);

        // Light and outline
        &#{$this}--outline {
            color: map-get($default-colours, primary);
        }
    }

    // Type
    &--solid {
        border: 1px solid transparent;
    }

    &--outline {
        background-color: transparent;
        border: 1px solid currentColor;
    }

    // Disabled 
    &--disabled {
        opacity: 0.5;
    }

    &--expand {
        width: 100%;
    }

    // Icon
    &--icon {
        text-align: left;
        padding-right: 2rem;
    }

    &__icon {
        position: absolute;
        right: 0.8rem;
        top: 50%;
        transform: translateY(-50%);
    }

    // Round
    &--round {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content:center;
        padding: 0 0 0 2px;
        float:right;
    }

}