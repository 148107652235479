.carousel {
    $this: &;
    background-color: map-get($default-colours, 'grey-base');
    border-radius: 4px;
    display: flex;
    position: relative;
    overflow: hidden;
    padding: 20px 3rem;


    &__list,
    &__track,
    &__slide {
        //height: 100%;
    }

    &__list {
        display: flex;
        flex: 1;
        position: relative;
        overflow: hidden;
    }

    &__track {
        display: flex;
        flex: 1;
        transition: all 300ms;
    }

    &__slide {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
    }

    &__nav {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &--prev {
            left: 1rem;
        }

        &--next {
            right: 1rem;
        }
    }

    &__button {
        padding: 1rem .5rem;
        margin: 0;
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
        color: white;
        opacity: 0.2;
    }
}

.slide {
    width: 100%;
    background: pink;
    color: black;
    height: 50px;
}