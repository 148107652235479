.button-group {
    $this: &;

    display: flex;
    flex-direction: column;

    &--column {
        @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
            flex-direction: row
        }
    }

    &__item {
        flex: 1;
        flex-basis: auto;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        #{$this}--column & {
            @media only screen and (min-width: map-get($breakpoints, 'tablet')) {
                &:not(:last-child) {
                    margin-right: 20px;
                    margin-bottom: 0;
                }
            }
        }

        // Button fill
        >* {
            width: 100%;
        }
    }
}