.pagination {
    &__list {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }

    &__item {
        margin: 0 0.5rem;
    }

    &__button {
        display: block;
        width: 30px;
        height: 30px;
        background-color: map-get($default-colours, grey-xlight);
        color: map-get($default-colours, grey-light);
        border: 0;

        &--active {
            background-color: map-get($default-colours, grey-light);
            color: white;
        }
    }
}

