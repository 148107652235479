.jumbotron {
    display: flex;
    flex-direction: column;
    padding: map-get($default-spacing, 'desktop');
    background-color: map-get($default-colours, black);
    background-size: cover;
    background-position: right center;
    border-radius: $border-radius;
    overflow: hidden;

    &__before {
        img {
            width: 142px;
            height: auto;
        }
    }

    &__content {
        display: flex;
        flex: 1;
        align-items: center;
        padding: map-get($default-spacing, 'desktop') * 2.5 0;

        @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
            padding: map-get($default-spacing, 'desktop') * 3.5 0;
        }
    }

    &__title {
        color: white;
        text-transform: uppercase;
        font-size: 2.2em;
        font-weight: 100;
        margin: 0;
    }

    &__actions {
        width: 100%;
    }
}