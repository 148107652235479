.poster {
    display: flex;    
    border-radius: $border-radius;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    position: relative;

    &__background {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    &__main {
        display: flex;
        position: relative;
        flex: 1;

        &--row {
            min-height: 100px;

            @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
                min-height: 200px;
            }
        }

        &--column {
            justify-content: flex-end;
        }
    }

    &__content {
        // display: flex;
        // flex-direction: column;
        padding: map-get($default-spacing, 'desktop');
        
        display: flex;
        flex-direction: column;

        &--small {
            padding: map-get($default-spacing, 'desktop');
        }

        &--row {
            flex: 1;
        }

        &--column {
            flex: .5;
        }

        &--overlay {
            background-color: rgba(0, 0, 0, 0.8);
        }
    }

    &__body {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: 1.5rem;
    }

    &__actions {
        margin-top: 1rem;
        padding: 24px;
        margin: 0 -24px -24px;
        border-radius: 0 0 5px 5px;

        &--overlay {
            background: #1d1d1d;
        }
        
        > * {
            &:not(:last-child) {
                margin-right: map-get($default-spacing, 'desktop');
            }
        }
    }

    // &__title {
    //     font-size: .875em;
    //     line-height: 1.5em;
    //     font-weight: 400;
    //     color: map-get($default-colours, primary);
    //     margin-top: 1rem;
    //     margin-bottom: 1rem;

    //     &:last-child {
    //         margin: 0;
    //     }

    //     &--small {
    //         font-size: 1.250em;
    //         line-height: 1.2em;
    //     }
    // }

    &__title {
        font-size: 1.25em;
        font-weight: 100;
        color: #fff;
        margin-bottom: 0.5rem;

        &--overlay {
            color: map-get($default-colours, primary);
        }
    }

    &__headline {
        font-size: .875em;
        line-height: 1.5em;
        font-weight: 400;
        color: map-get($default-colours, primary);
        margin-top: 1rem;
        margin-bottom: 1rem;

        &:last-child {
            margin: 0;
        }

        &--small {
            font-size: 1.250em;
            line-height: 1.2em;
        }
    }

    &__text {
        flex: 1;
        color: map-get($default-colours, white);
    }

    &__link {
        align-self: flex-end;
    }
}