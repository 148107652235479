.loader {
    display: flex;
    position: fixed;
    background-color: map-get($default-colours, grey-dark);
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999999;
    align-items: center;
    justify-content: center;

    &--enter {
        opacity: 0.999;
    }

    &--enter-active {
        opacity: 1;
        transition: opacity 100ms ease-in;
    }

    &--leave {
        opacity: 1;
    }

    &--leave-active {
        opacity: 0.01;
        transition: opacity 300ms ease-in;
    }

    &__inner {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &__text {
        color: map-get($default-colours, primary)
    }

    &__spinner {
        color: map-get($default-colours, primary)
    }
}