.nav-box {
    $this: &;
    
    display: flex;
    flex-direction: column;

    &--column {
        flex-direction: row;
        overflow-x: auto;
    }

    &__item {
        flex: 1;

        &:not(:last-child) {
            margin-right: 10%;
            margin-bottom: 0;
        }
    }
}