.tools-menu {
  vertical-align: middle;
  display: inline-block;

  &__button {
    cursor: pointer;
    float: left;
  }


  &__panel {
    position: absolute;
    width: 250px;
    background-color: white;
    left: 10px;
    flex-wrap: wrap;
    padding: 10px;
    border: 1px solid #383838;
    align-items: flex-start;
    margin-top: 35px;
    display: none;

    &--visible {
      display: flex;

      &::before {
        position: absolute;
        top: -8px;
        left: 13px;
        display:block;
        content:'';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 12px 15px 12px;
        border-color: transparent transparent #ffffff transparent;
      }
    }

  }

  &__item {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;    
    flex-wrap: wrap;
    border:1px solid transparent;

    &:hover {
      border:1px solid #333;
    }

  }

  &__group {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #1D1D1D;    
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    width: 100%;
    max-width: 30px;
  }

  &__label {
    color: #786c6c;
    // font-family: 'Barlow Condensed', sans-serif;
    // font-weight: 700;
    line-height: 1 !important;
    display: inline-block;
    text-decoration: none;
    font-size: 11px;
    margin-top:5px;
  }


}