.panel-tabs {
	$this: &;

	-webkit-tap-highlight-color: transparent;

	&__tab-list {
		display: flex;
		flex-direction: row;
		padding: 0;
	}

	&__tab {
		flex: 1;
		bottom: -1px;
		position: relative;
		list-style: none;
		padding: 2rem 1.5rem;
		cursor: pointer;
		background-color: map-get($default-colours, 'grey-xlight');
		box-shadow: inset 0 0 2px map-get($default-colours, 'grey-dark');
		z-index: 1;
		
		&--selected {
			box-shadow: none;
			z-index: 0;
			background: #fff;
			color: black;	
		}

		&--disabled {
			color: GrayText;
			cursor: default;
		}

		&:focus {
			box-shadow: 0 0 5px hsl(208, 99%, 50%);
			border-color: hsl(208, 99%, 50%);
			outline: none;

			// &:after {
			// 	content: "";
			// 	position: absolute;
			// 	height: 5px;
			// 	left: -4px;
			// 	right: -4px;
			// 	bottom: -5px;
			// 	background: #fff;
			// }
		}
	}

	&__tab-text {
		font-size: 1.500em;
		font-weight: 100;
		margin-bottom: 0;

		&::after {
			#{$this}__tab--selected & {
				content: '';
				display: block;
				width: 40px;
				height: 5px;
				background-color: map-get($default-colours, grey-dark);
				margin-top: 4px;
			}
        }
	}

	&__tab-panel {
		display: none;

		&--selected {
			display: block;
		}
	}
}