.animated-slide {
    height: 100%;
    width: 100%;
    overflow:hidden;
    background-size: cover;
    border-radius: 4px;

    h2 {
        font-size: 2rem;
        font-weight: 300;
    }

    h3 {
        font-size: 1.5rem;
        line-height: 1.8rem;
        font-weight: 600;
    }

    &__slide {
        flex: 1;
        position: relative;
        padding: 30px 20px;
        width: 100%;
        height: 100%;
    }

    &__title {
        color: #fff;
        
        .slick-active & {
            position: relative;
            animation: slideInLeft ease 2s;
        }
    }

    &__icon {
        position: absolute;
        right: 10px;
        bottom: 10px;

        .slick-active & {
            animation: slideInRight ease 2s;
        }
    }

    .slick-slider {
        height: 100%;
    }

    .slick-list {
        height: 100%;
    }

    .slick-track {
        height: 100%;
    }
    .slick-track .slick-slide {
        height: 100%;
    }
    .slick-track .slick-slide > div {
        height: 100%;
    }
}

@keyframes slideInLeft {
    0% {opacity:0; left: -200px}
    100% {opacity:1; left: 0;}
}

@keyframes slideInRight {
    0% {opacity:0; right: -200px}
    100% {opacity:1; right: 10px;}
}