// Activated on the body
.lightbox-gallery-active {
    overflow: hidden;
}

.lightbox-gallery {
    &__container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99999;
    
        background-color: rgba(0, 0, 0, .8);

        &--enter {
            opacity: 0;
        }
    
        &--enter-active {
            opacity: 1;
            transition: opacity 100ms ease-in;
        }
    
        &--leave {
            opacity: 1;
        }
    
        &--leave-active {
            opacity: 0.01;
            transition: opacity 300ms ease-in;
        }
    }

    &__inner {
        height: 100%;
        width: 100%;
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 3rem 5rem;
    }


    &__image {
        position: relative;
        margin: 0 auto;
        

        img {
            width: auto;
            max-width: 100%;
            height: auto;
            display: block;
            line-height: 0;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            padding: 5rem 0;
            margin: 0 auto;
        }

        figcaption {
            margin-top: -4rem;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            cursor: auto;
            color: white;
            font-size: 14px;
        }
    }

    &__iframe {
        line-height: 0;
        width: 100%;
        max-width: 900px;
    }

    &__text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        color: white;
        background-color: map-get($default-colours, grey-base);
        padding: 2rem;
    }

    &__nav {}

    &__button {
        overflow: visible;
        cursor: pointer;
        background: 0 0;
        border: 0;
        -webkit-appearance: none;
        display: block;
        outline: 0;
        padding: 0;
        z-index: 1046;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: white;
    }

    &__close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        font-size: 38px;
        line-height: 38px;
        width: 38px;
        height: 38px;
    }

    &__prev,
    &__next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100px;
        height: 80px;
    }

    &__prev {
        left: 0;
    }

    &__next {
        right: 0;
    }


}