/* autoprefixer grid: autoplace */

// Reset
@import '../../node_modules/css-reset-and-normalize/scss/reset-and-normalize.scss';

// Theme
@import "theme";

// Base
@import "base/typography";
@import "base/helpers";


// Layout
@import "layout/global";
@import "layout/dashboard";
@import "layout/page";
@import "layout/fullwidth";

// Components 
@import "components/animated-slide";
@import "components/breadcrumbs";
@import "components/button";
@import "components/button-group";
@import "components/card";
@import "components/carousel";
@import "components/checkbox";
@import "components/content-modal";
@import "components/content-modal-grid";
@import "components/divider";
@import "components/expand-button";
@import "components/feature-button";
@import "components/gallery";
@import "components/highlight-card";
@import "components/icon";
@import "components/iframe";
@import "components/image";
@import "components/jumbotron";
@import "components/jumbotron-button";
@import "components/label";
@import "components/lightbox-gallery";
@import "components/list";
@import "components/list-item";
@import "components/loader";
@import "components/modal";
@import "components/nav";
@import "components/nav-box";
@import "components/nav-grid";
@import "components/nav-tab";
@import "components/nav-tabs";
@import "components/nav-tile";
@import "components/navbar";
@import "components/navbar-button";
@import "components/pagination";
@import "components/panel";
@import "components/panel-tabs";
@import "components/poster";
@import "components/profile";
@import "components/react-tooltip-lite";
@import "components/search-box";
@import "components/title";
@import "components/title-button";
@import "components/tools-menu";
@import "components/yammer";

body {
    background-color: #262626;
}