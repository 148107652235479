.search-box {
    background-color: white;

    &__form {
        display: flex;
    }

    &__input {
        flex: 1;
        border: none;
        background-color: transparent;
        padding: .5rem;
        line-height: 24px;
        font-size: 0.938em;
    }

    &__button {
        vertical-align: middle;
        padding: .5rem;
        margin: 0;
        line-height: 0;
        font-size: 0;
        background-color: transparent;
        border: 0;
    }

    &__icon {}
}