.global {
    &__header {
        &--fixed {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 98;
        }
    }

    &__footer {}
}